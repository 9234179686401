import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { PhaseDna } from '../../models/phase-dna';
import { AnalyticsService } from '../../services/analytics.service';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-dna-phases',
  templateUrl: './dna-phases.component.html'
})
export class DnaPhasesComponent implements OnInit {
  public pieChart: Chart;
  public phaseData;
  public phaseMaxValue = 0;
  public colors = ['#753BBD', '#FE5000', '#FF8200', '#EB6FBD', '#00B5E2', '#00A3AD', '#008578', '#6CC24A'];
  public isLoading = true;

  @Output() phaseSelected = new EventEmitter<PhaseDna>();
  @Input() phase: PhaseDna;
  @Input() careModuleUid: string;


  constructor(
    public analyticsService: AnalyticsService,
    public hcpService: HcpService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['careModuleUid']?.currentValue || this.careModuleUid) {
      this.loadPhases();
    }
  }

  loadPhases() {
    this.phaseData =  [];
    if (this.phase && !this.phase?.has_sub_phases) {
      return;
    }

    this.isLoading = true;
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.analyticsService.phaseProgress(hospitalUid, hcpUid, this.careModuleUid, this.phase?.uid).subscribe({
      next: result => {
        this.isLoading = false;
        this.phaseData = result;
        this.phaseMaxValue = Math.max.apply(Math, this.phaseData.map( function(i) { return i.value; }));
        this.buildPhasePieChart();
      }, error: () => this.isLoading = false
    });
  }

  barPercent(value) {
    return ((value / this.phaseMaxValue) * 100) + '%';
  }

  buildPhasePieChart() {
    const params: any = this.getPhasePieParams();
    this.pieChart = new Chart(params);
  }


  getPhasePieParams() {
    const docDirIsRTL = document?.documentElement?.dir === 'rtl';

    const baseParams: any = {
      chart: {
        backgroundColor: 'transparent'
      },

      plotOptions: {
        pie: {
          states: { hover: { enabled: false } },
          colors: this.colors,
          dataLabels: {
            connectorColor: 'gray'
          }
        },
        series: {
          enableMouseTracking: false
        }
      },

      title: {
        text: null
      },

      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },

      legend: {
        rtl: docDirIsRTL
      },

      series: [{
        type: 'pie',
        innerSize: '50%',
        data: this.phaseData.map(item => ({y: item.value, name: (item.percent + '%')})),
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '14',
            color: 'gray'
          }
        }
      }]
    };

    return baseParams;
  }

  selectPhase(event: MouseEvent, phase: PhaseDna) {
    event?.preventDefault();
    this.phaseSelected.emit(phase);
  }
}
